
const mapIntlToDomesticFlightWaY = {
    "S": "oneway",
    "D" : "roundtrip"
}
export const parseDomesticUrl = ({flightWay, dDate, dCityCode, dAirportCode, aCityCode, aDate, aAirportCode }) => {
    let dFlightWay = mapIntlToDomesticFlightWaY[flightWay],
        dCode = dAirportCode ? `${dCityCode},${dAirportCode}` : dCityCode,
        aCode = aAirportCode ? `${aCityCode},${aAirportCode}` : aCityCode,
        date = aDate ? `${dDate},${aDate}` : `${dDate}`

        return `/itinerary/${dFlightWay}/${dCode}-${aCode}?date=${date}`.toLowerCase()
}