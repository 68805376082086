import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { fetchPost } from '../../../../actions/common'
import { UbtHandler } from '../../../../ubt.v2/handler'
import CONFIG_URL from '../../../../constants/list/api'
import { parseDomesticUrl } from '../../../../sources/common/parseDomesticUrl'
import { getIfSwitchOpenAsync } from '../../../../sources/common/getIfSwitchOpenAsync'
import { ExposeStat } from '../../../../ubt.v2/exposeStat'


/**
 * 主题推荐
 */
const ThemeRecommend = ({ prevCond, setShowRecommend, showNoresultRecommendHeader, showNearByRecommend }) => {
    const parseSegment = useCallback(() => {
        const flightWay = prevCond.get('flightWay'),
            flightSegments = prevCond.get('flightSegments'),
            segment = flightSegments.getIn([0]),
            dDate = segment.getIn(['departureDate']),
            dCityCode = segment.get('departureCityCode'),
            dAirportCode = segment.get('departureAirportCode'),
            aCityCode = segment.get('arrivalCityCode'),
            aCityName = segment.get('arrivalCityName'),
            cabin = prevCond.get('cabin')
        return { flightWay, dDate, dCityCode, dAirportCode, aCityCode, cabin, aCityName }
    }, [prevCond]),
        [cityList, setCityList] = useState([]),
        [switchOpen, setSwitchOpen] = useState(false),
        { flightWay, dDate, dCityCode, dAirportCode, aCityCode, aCityName } = parseSegment(),
        onCityItemClik = useCallback(({ aCityCode, index, city }) => {
            const url = parseDomesticUrl({ flightWay, dAirportCode, aCityCode, dCityCode, dDate })
            UbtHandler.onUploadData('c_theme_recommend_click', { index, city, url })
            window.open(url)
        }),
        enabelFetchData = useMemo(() => {
            return switchOpen && flightWay === "S"
        }, [flightWay, switchOpen])
    useEffect(() => {
        // 只在单程的时候参与实验
        setShowRecommend(false)
        if (flightWay != 'S' || switchOpen) {
            return
        }
        getIfSwitchOpenAsync('themeRecommendSwitch').then(opened => {
            setSwitchOpen(opened)
        })
    }, [flightWay, switchOpen])

    useEffect(() => {
        if (!enabelFetchData) {
            return
        }
        setCityList([])
        fetchPost(CONFIG_URL.getThemeRecommmend, {
            departureCityCode: dCityCode,
            arrivalCityCode: aCityCode,
            departureDate: dDate
        }).then(res => res && res.data || {}).then(data => {
            if (data.data && data.data.themeRecommendCityList && data.data.themeRecommendCityList.length) {
                setCityList(data.data.themeRecommendCityList)
                setShowRecommend(true)
                UbtHandler.onUploadData('c_theme_recommend_show', data.data.themeRecommendCityList)
            } else {
                setShowRecommend(false)
            }
        })
    }, [dCityCode, aCityCode, dDate, enabelFetchData])

    return cityList.length && flightWay === 'S' ?
        <ExposeStat ubtKey='c_theme_recommend_show_v2'>
            {showNoresultRecommendHeader && !showNearByRecommend ? <h3 className="no-result-recommend-header">未查询到符合条件的航班，为您推荐</h3> : null}
            <div className="recommend-container">
                <header>类似{aCityName}的国内旅游目的地</header>
                <section className="content">
                    <ul className="destination-recomend">
                        {
                            cityList.map((city, index) => <li key={index}>
                                <a onClick={() => { onCityItemClik({ aCityCode: city.code, index, city }) }}>
                                    <div className="pic-wrap">
                                        <img src={city.picUrl} alt=""/>
                                    </div>
                                    <div className="sub-title">
                                        <span className="name">{city.name}</span>
                                        <span className="price">
                                            <span className="tag">¥</span>
                                            {city.price}
                                            <span className="tag">起</span>
                                        </span>
                                    </div>
                                    <div className="desc" title={city.slogan}>{city.slogan}</div>
                                </a>
                            </li>)
                        }
                    </ul>
                </section>
            </div>
        </ExposeStat> : null
}

export default ThemeRecommend
